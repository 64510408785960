<template>
  <teleport to="#end-of-body">
    <div :class="transitionFromBottom ? 'drawer-bottom' : 'drawer-right'">
      <fade-transition>
        <div
          v-if="isOpen"
          :class="{ 'opacity-backdrop bg-ink': showBackdrop }"
          class="fixed pin drawer-backdrop"
          @click="closeDrawerFromBackground"></div>
      </fade-transition>
      <drawer-transition :position="transitionDirection">
        <div
          v-if="isOpen"
          class="fixed bottom-0 right-0 drawer flex flex-col"
          :class="[topAlignment, backgroundColourClass]">
          <div class="flex-1 overflow-auto">
            <div class="px-5 md:px-6 pt-5 md:pt-6 pb-3">
              <div class="md:p-3 flex flex-col">
                <div
                  v-if="!requiresInteraction"
                  class="self-end"
                  @click="manuallyCloseDrawer">
                  <svg-icon
                    class="cursor-pointer"
                    height="24px"
                    width="24px"
                    name="close-display" />
                </div>
                <slot name="header"></slot>
              </div>
            </div>
            <div
              v-if="$slots.content"
              class="px-5 md:px-6 pb-6">
              <div class="md:px-3">
                <slot name="content"></slot>
              </div>
            </div>
          </div>
          <div v-if="$slots.controls">
            <slot name="controls"></slot>
          </div>
        </div>
      </drawer-transition>
    </div>
  </teleport>
</template>

<script>
import SvgIcon from "@/app/javascript/components/shared/SvgIcon.vue";
import DrawerTransition from "@/app/javascript/components/transitions/DrawerTransition.vue";
import FadeTransition from "@/app/javascript/components/transitions/FadeTransition.vue";

export default {
  name: "Drawer",
  components: {
    SvgIcon,
    DrawerTransition,
    FadeTransition
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    transitionFromBottom: {
      type: Boolean,
      default: false
    },
    requiresInteraction: {
      type: Boolean,
      default: false
    },
    showBackdrop: {
      type: Boolean,
      default: true
    },
    preventBackgroundClose: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    backgroundColour: {
      type: String,
      default: "paper-1"
    }
  },
  emits: ["closeDrawer"],
  computed: {
    transitionDirection() {
      if (this.transitionFromBottom) return "bottom";
      return "right";
    },
    topAlignment() {
      return this.transitionFromBottom ? this.bottomDrawerHeight : "top-0";
    },
    backgroundColourClass() {
      return `bg-${this.backgroundColour}`;
    },
    bottomDrawerHeight() {
      if (this.fullHeight) {
        return "h-full";
      }
      return "max-height";
    }
  },
  watch: {
    isOpen(newVal) {
      const mmCornerPeel = document.querySelector(".mm-corner-peel");
      const emailPopUp = document.querySelector(".marketing-pop-up-container");

      this.toggleHiddenClass(mmCornerPeel, newVal);
      this.toggleHiddenClass(emailPopUp, newVal);
      this.toggleChatVisibility(newVal);

      document.documentElement.classList.toggle("overflow-y-hidden", newVal);
      document.body.classList.toggle("overflow-y-hidden", newVal);
      document.documentElement.classList.toggle("md:overflow-y-unset", newVal);
      document.body.classList.toggle("md:overflow-y-unset", newVal);
    }
  },
  created() {
    const onEscape = e => {
      if (this.isOpen && e.keyCode === 27) {
        this.$emit("closeDrawer");
      }
    };
    document.addEventListener("keydown", onEscape);
  },
  methods: {
    toggleHiddenClass(element, isHidden) {
      if (element) {
        if (isHidden) {
          element.classList.add("hidden");
        } else {
          element.classList.remove("hidden");
        }
      }
    },
    toggleChatVisibility(isHidden) {
      if (isHidden) {
        document.body.classList.add("hide-chat");
      } else {
        document.body.classList.remove("hide-chat");
      }
    },
    manuallyCloseDrawer() {
      if (!this.requiresInteraction) {
        this.$emit("closeDrawer");
      }
    },
    closeDrawerFromBackground() {
      if (!this.preventBackgroundClose) {
        this.manuallyCloseDrawer();
      }
    }
  }
};
</script>

<style lang="scss">
.drawer {
  width: 100%;
  z-index: 1000001;

  @media (min-width: $screen-md-min) {
    z-index: 1000001;
    overflow-y: scroll;
    overflow-x: hidden;
    width: 400px;
  }
}

.drawer-backdrop {
  z-index: 1000000;
}

.drawer-bottom {
  .max-height {
    max-height: calc(100vh - 80px);
  }
}
</style>
